import { ChainId } from '@elkdex/sdk'

//badge
import arbitrumIconBadge from 'assets/images/chains/badge/arbitrum.svg'
import artheraIconBadge from 'assets/images/chains/badge/arthera.svg'
import astarIconBadge from 'assets/images/chains/badge/astar.svg'
// import auroraIconBadge from 'assets/images/chains/badge/aurora.svg'
// import baseIconBadge from 'assets/images/chains/badge/base.svg'
import bitgertIconBadge from 'assets/images/chains/badge/bitgert.svg'
import bitTorrentIconBadge from 'assets/images/chains/badge/bitTorrent.svg'
// import blastIconBadge from 'assets/images/chains/badge/blast.svg'
// import bobaIconBadge from 'assets/images/chains/badge/boba.svg'
// import cloverIconBadge from 'assets/images/chains/badge/clover.svg'
import cronosIconBadge from 'assets/images/chains/badge/cronos.svg'
import elastosIconBadge from 'assets/images/chains/badge/elastos.svg'
import ethereumIconBadge from 'assets/images/chains/badge/ethereum.svg'
import fuseIconBadge from 'assets/images/chains/badge/fuse.svg'
import gnosisIconBadge from 'assets/images/chains/badge/gnosis.svg'
import harmonyIconBadge from 'assets/images/chains/badge/harmony.svg'
import hecoIconBadge from 'assets/images/chains/badge/heco.svg'
import hscIconBadge from 'assets/images/chains/badge/hsc.svg'
import iotexIconBadge from 'assets/images/chains/badge/iotex.png'
import kavaIconBadge from 'assets/images/chains/badge/kava.svg'
import kccIconBadge from 'assets/images/chains/badge/kcc.svg'
// import lineaIconBadge from 'assets/images/chains/badge/linea.svg'
// import mantleIconBadge from 'assets/images/chains/badge/mantle.svg'
// import moonbeamIconBadge from 'assets/images/chains/badge/moonbeam.svg'
// import metisIconBadge from 'assets/images/chains/badge/metis.svg'
import moonriverIconBadge from 'assets/images/chains/badge/moonriver.svg'
import neonIconBadge from 'assets/images/chains/badge/neon.svg'
import oecIconBadge from 'assets/images/chains/badge/oec.svg'
// import opbnbIconBadge from 'assets/images/chains/badge/opbnb.svg'
import optimismIconBadge from 'assets/images/chains/badge/optimism.svg'
import polygonIconBadge from 'assets/images/chains/badge/polygon.svg'
import rootstockIconBadge from 'assets/images/chains/badge/rootstock.svg'
// import scrollIconBadge from 'assets/images/chains/badge/scroll.svg'
// import qIconBadge from 'assets/images/chains/badge/q.svg'
import telosIconBadge from 'assets/images/chains/badge/telos.svg'
import velasIconBadge from 'assets/images/chains/badge/velas.svg'
import wanchainIconBadge from 'assets/images/chains/badge/wanchain.svg'
// import zkevmIconBadge from 'assets/images/chains/badge/zkevm.svg'
// circle
import arbitrumIconCircle from 'assets/images/chains/circle/arbitrum.svg'
import artheraIconCircle from 'assets/images/chains/circle/arthera.svg'
import astarIconCircle from 'assets/images/chains/circle/astar.svg'
import avalancheIconCircle from 'assets/images/chains/circle/avalanche.svg'
import baseIconCircle from 'assets/images/chains/circle/base.svg'
import bitgertIconCircle from 'assets/images/chains/circle/bitgert.svg'
import bitTorrentIconCircle from 'assets/images/chains/circle/bitTorrent.svg'
import bscIconCircle from 'assets/images/chains/circle/bsc.svg'
import cronosIconCircle from 'assets/images/chains/circle/cronos.svg'
import elastosIconCircle from 'assets/images/chains/circle/elastos.svg'
import ethereumIconCircle from 'assets/images/chains/circle/ethereum.svg'
import fantomIconCircle from 'assets/images/chains/circle/fantom.svg'
import fuseIconCircle from 'assets/images/chains/circle/fuse.svg'
import gnosisIconCircle from 'assets/images/chains/circle/gnosis.svg'
import harmonyIconCircle from 'assets/images/chains/circle/harmony.svg'
import hecoIconCircle from 'assets/images/chains/circle/heco.svg'
import hscIconCircle from 'assets/images/chains/circle/hsc.svg'
import iotexIconCircle from 'assets/images/chains/circle/iotex.png'
import kavaIconCircle from 'assets/images/chains/circle/kava.svg'
import kccIconCircle from 'assets/images/chains/circle/kcc.svg'
// import lineaIconCircle from 'assets/images/chains/circle/linea.svg'
import metisIconCircle from 'assets/images/chains/circle/metis.svg'
import moonriverIconCircle from 'assets/images/chains/circle/moonriver.svg'
import neonIconCircle from 'assets/images/chains/circle/neon.svg'
import oecIconCircle from 'assets/images/chains/circle/oec.svg'
import optimismIconCircle from 'assets/images/chains/circle/optimism.svg'
import polygonIconCircle from 'assets/images/chains/circle/polygon.svg'
import qIconCircle from 'assets/images/chains/circle/q.svg'
import telosIconCircle from 'assets/images/chains/circle/telos.svg'
import velasIconCircle from 'assets/images/chains/circle/velas.svg'
import wanchainIconCircle from 'assets/images/chains/circle/wanchain.svg'
import { mapChainIdToTokensChainName, mapChainNameToChainId } from '../sdk'

export const CHAIN_ID_SELECTED_KEY = 'CHAIN_ID_SELECTED'

export interface ChainInfo {
  readonly explorer: string
  readonly infoLink?: string
  readonly label: string
  readonly iconCircle?: string
  readonly iconBadge?: string
  readonly rpcUrls?: string[]
  readonly chainName?: string
  readonly tokenlist: string
  readonly coingeckoId: string
  readonly apollo: {
    clientUrl: string
    blockUrl: string
  }
  readonly nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
}

export const CHAIN_INFO: { [chainId: number]: ChainInfo } = {
  [ChainId.AVALANCHE]: {
    coingeckoId: 'avalanche-2',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/avax.tokenlist.json',
    explorer: 'https://snowtrace.io',
    infoLink: 'https://avax-info.elk.finance/',
    label: 'Avalanche',
    chainName: 'Avalanche C-Chain',
    iconCircle: avalancheIconCircle,
    iconBadge: avalancheIconCircle,
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
    apollo: {
      clientUrl: 'https://api.studio.thegraph.com/query/91831/avax-client/version/latest',
      blockUrl: 'https://api.studio.thegraph.com/query/91829/avax-block/version/latest',
    },
  },
  [ChainId.ARBITRUM]: {
    coingeckoId: 'ethereum',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/arbitrum.tokenlist.json',
    explorer: 'https://arbiscan.io',
    infoLink: 'https://arbitrum-info.elk.finance/',
    label: 'Arbitrum',
    chainName: 'Arbitrum One',
    iconCircle: arbitrumIconCircle,
    iconBadge: arbitrumIconBadge,
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    apollo: {
      clientUrl: 'https://api.studio.thegraph.com/query/92690/arbitrum-client/version/latest',
      blockUrl: 'https://api.studio.thegraph.com/query/92689/arbitrum-block/version/latest',
    },
  },
  [ChainId.ETHEREUM]: {
    coingeckoId: 'ethereum',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/ethereum.tokenlist.json',
    explorer: 'https://etherscan.io',
    infoLink: 'https://eth-info.elk.finance/',
    label: 'Ethereum',
    iconCircle: ethereumIconCircle,
    iconBadge: ethereumIconBadge,
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    apollo: {
      clientUrl: 'https://api.studio.thegraph.com/query/92692/ethereum-client/version/latest',
      blockUrl: 'https://api.studio.thegraph.com/query/92691/ethereum-block/version/latest',
    },
  },
  [ChainId.OPTIMISM]: {
    coingeckoId: 'ethereum',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/optimism.tokenlist.json',
    explorer: 'https://optimistic.etherscan.io',
    infoLink: 'https://optimism-info.elk.finance/',
    label: 'Optimism',
    chainName: 'Optimism',
    iconCircle: optimismIconCircle,
    iconBadge: optimismIconBadge,
    nativeCurrency: { name: 'Optimistic ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://mainnet.optimism.io'],
    apollo: {
      clientUrl: 'https://api.studio.thegraph.com/query/92688/optimism-client/version/latest',
      blockUrl: 'https://api.studio.thegraph.com/query/92687/optimism-block/version/latest',
    },
  },
  [ChainId.MATIC]: {
    coingeckoId: 'matic-network',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/matic.tokenlist.json',
    explorer: 'https://polygonscan.com',
    infoLink: 'https://matic-info.elk.finance/',
    label: 'Polygon',
    chainName: 'Polygon Mainnet',
    iconCircle: polygonIconCircle,
    iconBadge: polygonIconBadge,
    rpcUrls: ['https://polygon-rpc.com'],
    nativeCurrency: { name: 'Polygon', symbol: 'POL', decimals: 18 },
    apollo: {
      clientUrl: 'https://api.studio.thegraph.com/query/92672/polygon-client/version/latest',
      blockUrl: 'https://api.studio.thegraph.com/query/92671/polygon-block/version/latest',
    },
  },
  [ChainId.FANTOM]: {
    coingeckoId: 'fantom',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/ftm.tokenlist.json',
    explorer: 'https://ftmscan.com',
    infoLink: 'https://ftm-info.elk.finance/',
    label: 'Fantom',
    chainName: 'Fantom Opera',
    iconCircle: fantomIconCircle,
    iconBadge: fantomIconCircle,
    rpcUrls: ['https://rpc.ftm.tools'],
    nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
    apollo: {
      clientUrl: 'https://api.studio.thegraph.com/query/92675/fantom-client/version/latest',
      blockUrl: 'https://api.studio.thegraph.com/query/92674/fantom-block/version/latest',
    },
  },
  [ChainId.BINANCE]: {
    coingeckoId: 'binancecoin',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/bsc.tokenlist.json',
    explorer: 'https://bscscan.com',
    infoLink: 'https://bsc-info.elk.finance/',
    label: 'BNB Chain',
    chainName: 'Binance Smart Chain Mainnet',
    iconCircle: bscIconCircle,
    iconBadge: bscIconCircle,
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed1.binance.org'],
    apollo: {
      clientUrl: 'https://api.studio.thegraph.com/query/92679/bnb-client/version/latest',
      blockUrl: 'https://api.studio.thegraph.com/query/92678/bnb-block/version/latest',
    },
  },
  [ChainId.CRONOS]: {
    coingeckoId: 'crypto-com-chain',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/cronos.tokenlist.json',
    explorer: 'https://cronos.org/explorer',
    infoLink: 'https://cronos-info.elk.finance/',
    label: 'Cronos',
    nativeCurrency: { name: 'Cronos', symbol: 'CRO', decimals: 18 },
    rpcUrls: ['https://evm.cronos.org'],
    chainName: 'Cronos Mainnet Beta',
    iconCircle: cronosIconCircle,
    iconBadge: cronosIconBadge,
    apollo: {
      clientUrl: 'https://graphnode1.elk.finance/subgraphs/name/elkfinance-cronos',
      blockUrl: 'https://graphnode2.elk.finance/subgraphs/name/elkfinance-cronos-blocks',
    },
  },
  [ChainId.ELAETH]: {
    coingeckoId: 'elastos',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/elastos.tokenlist.json',
    explorer: 'https://eth.elastos.io',
    infoLink: 'https://elastos-info.elk.finance/',
    label: 'Elastos',
    chainName: 'Elastos Smart Chain',
    iconCircle: elastosIconCircle,
    iconBadge: elastosIconBadge,
    nativeCurrency: { name: 'Elastos', symbol: 'ELA', decimals: 18 },
    rpcUrls: ['https://api.elastos.io/eth'],
    apollo: {
      clientUrl: 'https://elastos-graph.elk.finance/subgraphs/name/elkfinance/elkdex-elastos',
      blockUrl: 'https://api.glidefinance.io/subgraphs/name/glide/blocks',
    },
  },
  [ChainId.FUSE]: {
    coingeckoId: 'fuse-network-token',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/fuse.tokenlist.json',
    explorer: 'https://explorer.fuse.io',
    infoLink: 'https://fuse-info.elk.finance/',
    label: 'Fuse',
    chainName: 'Fuse Mainnet',
    iconCircle: fuseIconCircle,
    iconBadge: fuseIconBadge,
    nativeCurrency: { name: 'Fuse', symbol: 'FUSE', decimals: 18 },
    rpcUrls: ['https://rpc.fuse.io'],
    apollo: {
      clientUrl: 'https://api.studio.thegraph.com/query/92686/fuse-client/version/latest',
      blockUrl: 'https://api.studio.thegraph.com/query/92685/fuse-block/version/latest',
    },
  },
  [ChainId.XDAI]: {
    coingeckoId: 'xdai',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/xdai.tokenlist.json',
    explorer: 'https://blockscout.com/xdai/mainnet',
    infoLink: 'https://xdai-info.elk.finance/',
    label: 'Gnosis',
    chainName: 'Gnosis Chain (formerly xDai)',
    iconCircle: gnosisIconCircle,
    iconBadge: gnosisIconBadge,
    nativeCurrency: { name: 'Gnosis', symbol: 'xDAI', decimals: 18 },
    rpcUrls: ['https://rpc.gnosischain.com'],
    apollo: {
      clientUrl: 'https://api.studio.thegraph.com/query/92677/gnosis-client/version/latest',
      blockUrl: 'https://api.studio.thegraph.com/query/92676/gnosis-block/version/latest',
    },
  },
  [ChainId.HARMONY]: {
    coingeckoId: 'harmony',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/harmony.tokenlist.json',
    explorer: 'https://explorer.harmony.one',
    infoLink: 'https://harmony-info.elk.finance/',
    label: 'Harmony',
    chainName: 'Harmony Mainnet Shard 0',
    iconCircle: harmonyIconCircle,
    iconBadge: harmonyIconBadge,
    nativeCurrency: { name: 'Harmony', symbol: 'ONE', decimals: 18 },
    rpcUrls: ['https://api.harmony.one'],
    apollo: {
      clientUrl: 'https://api.studio.thegraph.com/query/92682/harmony-client/version/latest',
      blockUrl: 'https://api.studio.thegraph.com/query/92681/harmony-block/version/latest',
    },
  },
  [ChainId.HOO]: {
    coingeckoId: 'hoo-token',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/hoo.tokenlist.json',
    explorer: 'https://hooscan.com',
    infoLink: 'https://hoo-info.elk.finance/',
    label: 'HSC',
    chainName: 'Hoo Mainnet RPC',
    iconCircle: hscIconCircle,
    iconBadge: hscIconBadge,
    nativeCurrency: { name: 'HSC', symbol: 'HOO', decimals: 18 },
    rpcUrls: ['https://http-mainnet.hoosmartchain.com'],
    apollo: {
      clientUrl: 'https://hoo-graph.elk.finance/subgraphs/name/elkfinance/elkdex-hoo',
      blockUrl: 'https://hoo-graph.elk.finance/subgraphs/name/elkfinance/hoo-blocks',
    },
  },
  [ChainId.HUOBIECO]: {
    coingeckoId: 'huobi-token',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/heco.tokenlist.json',
    explorer: 'https://hecoinfo.com',
    infoLink: 'https://heco-info.elk.finance/',
    label: 'HECO',
    chainName: 'Huobi ECO Chain Mainnet',
    iconCircle: hecoIconCircle,
    iconBadge: hecoIconBadge,
    nativeCurrency: { name: 'HT', symbol: 'HT', decimals: 18 },
    rpcUrls: ['https://http-mainnet-node.huobichain.com'],
    apollo: {
      clientUrl: 'https://heco-graph.elk.finance/subgraphs/name/elkfinance/elkdex-heco',
      blockUrl: 'https://heco-lite-graph.mdex.cc/subgraphs/name/mdex-blocks',
    },
  },
  [ChainId.IOTEX]: {
    coingeckoId: 'iotex',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/iotex.tokenlist.json',
    explorer: 'https://iotexscan.io',
    infoLink: 'https://iotex-info.elk.finance/',
    label: 'IoTeX',
    chainName: 'IoTeX Network Mainnet',
    iconCircle: iotexIconCircle,
    iconBadge: iotexIconBadge,
    nativeCurrency: { name: 'IOTX', symbol: 'IOTX', decimals: 18 },
    rpcUrls: ['https://babel-api.mainnet.iotex.io'],
    apollo: {
      clientUrl: 'https://graphnode1.elk.finance/subgraphs/name/elkfinance-iotex',
      blockUrl: 'https://api.studio.thegraph.com/query/94415/iotex-block/version/latest',
    },
  },
  [ChainId.KUCOIN]: {
    coingeckoId: 'kucoin-shares',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/kcc.tokenlist.json',
    explorer: 'https://scan.kcc.io',
    infoLink: 'https://kcc-info.elk.finance/',
    label: 'KCC',
    chainName: 'KCC Mainnet',
    iconCircle: kccIconCircle,
    iconBadge: kccIconBadge,
    nativeCurrency: { name: 'KCC', symbol: 'KCS', decimals: 18 },
    rpcUrls: ['https://rpc-mainnet.kcc.network'],
    apollo: {
      clientUrl: 'https://graphnode1.elk.finance/subgraphs/name/elkfinance-kcc',
      blockUrl: 'https://graphnode2.elk.finance/subgraphs/name/elkfinance-kcc-blocks',
    },
  },
  [ChainId.MOONRIVER]: {
    coingeckoId: 'moonriver',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/moonriver.tokenlist.json',
    explorer: 'https://moonriver.moonscan.io',
    infoLink: 'https://moonriver-info.elk.finance/',
    label: 'Moonriver',
    chainName: 'Moonriver',
    iconCircle: moonriverIconCircle,
    iconBadge: moonriverIconBadge,
    nativeCurrency: { name: 'Moonriver', symbol: 'MOVR', decimals: 18 },
    rpcUrls: ['https://rpc.api.moonriver.moonbeam.network'],
    apollo: {
      clientUrl: 'https://api.studio.thegraph.com/query/92684/moonriver-client/version/latest',
      blockUrl: 'https://api.studio.thegraph.com/query/92683/moonriver-block/version/latest',
    },
  },
  [ChainId.OKEX]: {
    coingeckoId: 'oec-token',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/okex.tokenlist.json',
    explorer: 'https://www.oklink.com',
    infoLink: 'https://okex-info.elk.finance/',
    label: 'OKC',
    chainName: 'OKExChain Mainnet',
    iconCircle: oecIconCircle,
    iconBadge: oecIconBadge,
    nativeCurrency: { name: 'OKEx', symbol: 'OKT', decimals: 18 },
    rpcUrls: ['https://exchainrpc.okex.org'],
    apollo: {
      clientUrl: 'https://graphnode1.elk.finance/subgraphs/name/elkfinance-okx',
      blockUrl: 'https://graphnode2.elk.finance/subgraphs/name/elkfinance-okx-blocks',
    },
  },
  [ChainId.TELOS]: {
    coingeckoId: 'telos',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/telos.tokenlist.json',
    explorer: 'https://www.teloscan.io',
    infoLink: 'https://telos-info.elk.finance/',
    label: 'Telos',
    iconCircle: telosIconCircle,
    iconBadge: telosIconBadge,
    chainName: 'Telos EVM Mainnet',
    nativeCurrency: { name: 'Telos', symbol: 'TLOS', decimals: 18 },
    rpcUrls: ['https://mainnet.telos.net/evm'],
    apollo: {
      clientUrl: 'https://graphnode1.elk.finance/subgraphs/name/elkfinance-telos',
      blockUrl: 'https://graphnode2.elk.finance/subgraphs/name/elkfinance-telos-blocks',
    },
  },
  [ChainId.KAVA]: {
    coingeckoId: 'kava',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/kava.tokenlist.json',
    explorer: 'https://explorer.kava.io',
    // infoLink: 'https://kava-info.elk.finance/',
    label: 'Kava',
    chainName: 'Kava EVM Co-Chain',
    iconCircle: kavaIconCircle,
    iconBadge: kavaIconBadge,
    nativeCurrency: { name: 'Kava', symbol: 'KAVA', decimals: 18 },
    rpcUrls: ['https://evm.kava.io'],
    apollo: {
      clientUrl: 'https://graphnode1.elk.finance/subgraphs/name/elkfinance-kava',
      blockUrl: 'https://graphnode2.elk.finance/subgraphs/name/elkfinance-kava-blocks',
    },
  },
  [ChainId.Q]: {
    coingeckoId: 'q-protocol',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/q.tokenlist.json',
    explorer: 'https://explorer.q.org',
    label: 'Q',
    chainName: 'Q Mainnet',
    iconCircle: qIconCircle,
    iconBadge: qIconCircle,
    nativeCurrency: { name: 'QGOV', symbol: 'QGOV', decimals: 18 },
    apollo: {
      clientUrl: 'https://graphnode1.elk.finance/subgraphs/name/elkfinance-q',
      blockUrl: 'https://graphnode2.elk.finance/subgraphs/name/elkfinance-q-blocks',
    },
  },
  [ChainId.BASE]: {
    coingeckoId: 'ethereum',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/base.tokenlist.json',
    explorer: 'https://basescan.org',
    label: 'Base',
    chainName: 'Base',
    iconCircle: baseIconCircle,
    iconBadge: baseIconCircle,
    nativeCurrency: { name: 'Base ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://mainnet.base.org'],
    apollo: {
      clientUrl: 'https://api.studio.thegraph.com/query/91840/base-client/version/latest',
      blockUrl: 'https://api.studio.thegraph.com/query/91839/base-block/version/latest',
    },
  },
  [ChainId.ROOTSTOCK]: {
    coingeckoId: 'rootstock',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/rootstock.tokenlist.json',
    explorer: 'https://explorer.rootstock.io',
    label: 'Rootstock',
    chainName: 'Rootstock Mainnet',
    iconCircle: rootstockIconBadge,
    iconBadge: rootstockIconBadge,
    nativeCurrency: { name: 'RBTC', symbol: 'RBTC', decimals: 18 },
    apollo: {
      clientUrl: 'https://api.studio.thegraph.com/query/87715/elkfinance-rootstock/version/latest',
      blockUrl: 'https://api.studio.thegraph.com/query/93124/rootstock-block/version/latest',
    },
  },
  [ChainId.BTTC]: {
    coingeckoId: 'bittorrent',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/bttc.tokenlist.json',
    explorer: 'https://bttcscan.com',
    label: 'BitTorrent',
    chainName: 'BitTorrent Chain Mainnet',
    iconCircle: bitTorrentIconCircle,
    iconBadge: bitTorrentIconBadge,
    nativeCurrency: { name: 'BitTorrent', symbol: 'BTT', decimals: 18 },
    apollo: {
      clientUrl: 'https://graphnode1.elk.finance/subgraphs/name/elkfinance-bttc',
      blockUrl: 'https://graphnode2.elk.finance/subgraphs/name/elkfinance-bttc-blocks',
    },
  },
  [ChainId.NEON_MAINNET]: {
    coingeckoId: 'neon',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/neon.tokenlist.json',
    explorer: 'https://neonscan.org',
    label: 'Neon',
    chainName: 'Neon EVM MainNet',
    iconCircle: neonIconCircle,
    iconBadge: neonIconBadge,
    nativeCurrency: { name: 'Neon', symbol: 'NEON', decimals: 18 },
    apollo: {
      clientUrl: 'https://graphnode1.elk.finance/subgraphs/name/elkfinance-neon',
      blockUrl: 'https://graphnode2.elk.finance/subgraphs/name/elkfinance-neon-blocks',
    },
  },
  [ChainId.BITGERT]: {
    coingeckoId: 'bitrise-token',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/bitgert.tokenlist.json',
    explorer: 'https://brisescan.com/',
    label: 'Bitgert',
    chainName: 'Bitgert Mainnet',
    iconCircle: bitgertIconCircle,
    iconBadge: bitgertIconBadge,
    nativeCurrency: { name: 'Brise', symbol: 'BRISE', decimals: 18 },
    apollo: {
      clientUrl: 'https://graphnode1.elk.finance/subgraphs/name/elkfinance-bitgert',
      blockUrl: 'https://graphnode2.elk.finance/subgraphs/name/elkfinance-bitgert-blocks',
    },
  },
  [ChainId.WANCHAIN]: {
    coingeckoId: 'wanchain',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/wan.tokenlist.json',
    explorer: 'https://www.wanscan.org',
    label: 'Wanchain',
    chainName: 'Wanchain',
    iconCircle: wanchainIconCircle,
    iconBadge: wanchainIconBadge,
    nativeCurrency: { name: 'Wanchain', symbol: 'WAN', decimals: 18 },
    apollo: {
      clientUrl: 'https://graphnode1.elk.finance/subgraphs/name/elkfinance-wan',
      blockUrl: 'https://graphnode2.elk.finance/subgraphs/name/elkfinance-wan-blocks',
    },
  },
  [ChainId.METIS]: {
    coingeckoId: 'metis-token',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/metis.tokenlist.json',
    explorer: 'https://andromeda-explorer.metis.io',
    label: 'Metis',
    chainName: 'Metis Andromeda Mainnet',
    iconCircle: metisIconCircle,
    iconBadge: metisIconCircle,
    nativeCurrency: { name: 'Metis', symbol: 'METIS', decimals: 18 },
    apollo: {
      clientUrl: 'https://graphnode1.elk.finance/subgraphs/name/elkfinance-metis',
      blockUrl: 'https://graphnode2.elk.finance/subgraphs/name/elkfinance-metis-blocks',
    },
  },
  [ChainId.VELAS]: {
    coingeckoId: 'velas',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/velas.tokenlist.json',
    explorer: 'https://evmexplorer.velas.com',
    label: 'Velas',
    chainName: 'Velas EVM Mainnet',
    iconCircle: velasIconCircle,
    iconBadge: velasIconBadge,
    nativeCurrency: { name: 'Velas', symbol: 'VLX', decimals: 18 },
    apollo: {
      clientUrl: 'https://graphnode1.elk.finance/subgraphs/name/elkfinance-velas',
      blockUrl: 'https://graphnode2.elk.finance/subgraphs/name/elkfinance-velas-blocks',
    },
  },
  [ChainId.ARTHERA]: {
    coingeckoId: 'arthera',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/arthera.tokenlist.json',
    explorer: 'https://explorer.arthera.net/',
    label: 'Arthera',
    chainName: 'Arthera Mainnet',
    iconCircle: artheraIconCircle,
    iconBadge: artheraIconBadge,
    nativeCurrency: { name: 'Arthera', symbol: 'AA', decimals: 18 },
    apollo: {
      clientUrl: 'https://graphnode1.elk.finance/subgraphs/name/elkfinance-arthera',
      blockUrl: 'https://graphnode2.elk.finance/subgraphs/name/elkfinance-arthera-blocks',
    },
  },
  [ChainId.ASTAR]: {
    coingeckoId: 'astar',
    tokenlist: 'https://raw.githubusercontent.com/elkfinance/tokens/main/astar.tokenlist.json',
    explorer: 'https://astar.subscan.io',
    label: 'Astar',
    chainName: 'Astar',
    iconCircle: astarIconCircle,
    iconBadge: astarIconBadge,
    nativeCurrency: { name: 'Astar', symbol: 'ASTR', decimals: 18 },
    apollo: {
      clientUrl: 'https://graphnode1.elk.finance/subgraphs/name/elkfinance-astar',
      blockUrl: 'https://graphnode2.elk.finance/subgraphs/name/elkfinance-astar-blocks',
    },
  },
}

if (!localStorage?.getItem(CHAIN_ID_SELECTED_KEY)) {
  localStorage?.setItem(CHAIN_ID_SELECTED_KEY, mapChainIdToTokensChainName[ChainId.AVALANCHE])
}

export const CHAIN_NAME = localStorage?.getItem(CHAIN_ID_SELECTED_KEY)
export const CHAIN_ID = mapChainNameToChainId(CHAIN_NAME)

export const CHAIN_INFO_SELECTED = CHAIN_INFO[CHAIN_ID]
